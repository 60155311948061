<template>
    <modal class="transInfoModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">转账</div>
        <div class="modal-body">
            <div class="title">账户信息</div>
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">应收账户：</span>
                    <div class="from-input">{{data?.Corp_Name}}</div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">转账金额：</span>
                    <div class="from-input">
                        <span class="money">￥{{data?.money}}</span><span class="number">合计：{{data?.number}}笔</span>
                    </div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">转入账户：</span>
                    <select-tree class="from-input" v-model="corpTransID" placeholder="请选择账户" 
                        :isMultiple="false" :data="treeCorpData" 
                        searchPlaceholder="编号名称模糊检索"
                        is-search
                        :filter-node-method="filterNode"
                        title="转入账户选择" />
                </div>
                <div class="li-flex">
                    <span class="lable-text">备注：</span>
                    <div class="from-input"><input type="text" v-model="remark" maxlength="25"/></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()"> 确认</button>
        </div>
    </modal>
</template>

<script>

// 转账
export default {
    name:'transInfoModel',
    emits:["close","confirm"],
    props:{
        isShow:Boolean,
        data:{
            typeof:Object,
            default:null
        }
    },
    data(){
        return{
            /**账户数据 */
            ARCorpInfoList:[],
            //转入账户
            corpTransID:"",
            //备注
            remark:''
        }
    },
    computed:{
        /**选择 账户下拉数据*/
        treeCorpData(){
            let tree=[];
            let category={}
            this.ARCorpInfoList?.forEach(it=>{
                if(it.Corp_AutoID!=this.data?.Corp_AutoID){
                    if(!category[it.Corp_KindID]){
                        category[it.Corp_KindID]={
                            id:"kind"+it.Corp_KindID,
                            name:it.Corp_KindName,
                            children:[]
                        }
                        tree.push(category[it.Corp_KindID]);
                    }
                    category[it.Corp_KindID].children.push(Object.assign({
                        id:it.Corp_AutoID,
                        name:it.Corp_Code+"-"+it.Corp_Name,
                    },it));
                }
            })
            return tree;
        },
    },
    mounted(){
        this.loadData();
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.corpTransID="";
                this.remark=""
                this.loadData();
            }
        }
    },
    methods:{
        hide(){
            this.$emit("close");
        },
        //加载数据
        loadData(){
            if(this.ARCorpInfoList?.length>0){
                return;
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetBestARCorpInfo", {}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.ARCorpInfoList=d.ResponseBody||[];
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("账户数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /**搜索 */
        filterNode(value, data){
            if (!value) return true;
            value=value.toLocaleUpperCase();
            return data.Corp_Name?.toLocaleUpperCase().includes(value) || data.Corp_Code?.toLocaleUpperCase().includes(value);
        },
        /**点击确认 */
        confirm(){
            if(!this.corpTransID){
                this.$message.warning("转入账户不能为空!");
                return;
            }
            
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let Corp_ConsumeIDList=this.data?.Corp_ConsumeIDList?.map(it=>it.Corp_ConsumeID);
            this.$httpAES.post("Bestech.CloudPos.TransARCorpInfo",{
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Corp_AutoID:this.data?.Corp_AutoID,//原账户ID
                Corp_TransID:this.corpTransID,//转入用户ID
                Corp_SumMoney:this.data?.money,//总金额
                Remark:this.remark,
                LastUpdated_Time:this.data?.LastUpdated_Time,//原账户最后更新时间
                //明细主键List
                Corp_ConsumeIDList:Corp_ConsumeIDList
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("转账成功");
                    this.$emit("confirm",Corp_ConsumeIDList,d.ResponseBody?.LastUpdated_Time);
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('转账失败：'+e.message);
                console.log('转账失败：',e);
            })
        }
    }
}
</script>

<style lang="scss">
@import './transInfoModel.scss'
</style>